/**
 * eBay Slice
 */
const { createSlice, createAction } = require("@reduxjs/toolkit");

const initialState = {
  link: null,
  listing: [{}],
};

const eBaySlice = createSlice({
  name: "eBay",
  initialState,
  reducers: {
    setAuthLink(state, action) {
      state.link = action.payload;
    },
    setListings(state, action) {
      state.listing = action.payload;
    },
  },
});

export const eBayReducer = eBaySlice.reducer;

export const { setAuthLink, setListings } = eBaySlice.actions;

export const getAuthLink = createAction("eBAY/AUTH-LINK");
export const getEBayList = createAction("eBAY/LISTINGS"); 
export const uploadEBayFile = createAction("eBAY/UPLOAD_FILE"); 
