/**
 * Auth sagas
 * @format
 */

import { put, takeLatest } from "redux-saga/effects";

import { httpRequest, endPoints } from "@src/services";
import { createLoader, setAuthToken, setUser, showToast } from "../common";
import { login } from "./slice";

/**
 * Login with email and password
 * @param {*} email
 * @param {*} password
 */
function* loginSaga({ payload }) {
  const loader = createLoader();
  try {
    yield put(loader.present());
    const { username, password, onSuccess } = payload;
    const response = yield httpRequest.post(endPoints.login, {
      username,
      password,
    });
    yield put(setAuthToken(response?.accessToken));
    yield put(setUser(response));
    yield onSuccess();
  } catch (error) {
    console.log({ error });
    yield put(showToast("Invalid Login Credentials"));
  } finally {
    yield put(loader.dismiss());
  }
}

export function* authSaga() {
  yield takeLatest(login, loginSaga);
}
