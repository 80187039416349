/**
 * Login Page
 * @flow
 * @format
 */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Images, MainRoutes, Strings } from "@src/constants";
import { login } from "../slice";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(login({ username: email, password, onSuccess }));
  };

  const onSuccess = () => {
    navigate(MainRoutes.HomeScreen, { replace: true });
  };

  return (
    <section className="login">
      {/* <!-- patterns --> */}
      <div className="absolute-item one">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      <div className="absolute-item two">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      <div className="absolute-item three">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      {/* <!-- illustration --> */}
      <img src={Images.login} alt="" className="abs-img" />
      {/* <!-- navigation --> */}

      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          {/* <!-- logo --> */}
          <a className="navbar-brand" href="#">
            {" "}
            <img src={Images.logo} alt="" />{" "}
          </a>
          {/* <!-- responsive-toggle-btn --> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <!-- nav-links --> */}
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  {Strings.help}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="login.html"
                >
                  {Strings.login}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid p-16vh">
        <div className="row justify-content-between">
          <div className="col-md-6">
            <div className="login-title xl">
              Sign in to <br />
              {Strings.appTitle}
            </div>
          </div>
          <div className="col-md-6">
            <div className="login-form">
              <div className="login-title md">{Strings.weclome}</div>
              <div className="form">
                <form onSubmit={onSubmit}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="usename"
                    value={email}
                    onChange={(e) => setEmail(e.target?.value)}
                    required
                  />
                  <input
                    type="password"
                    className="form-control"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target?.value)}
                    required
                  />
                  <div className="frgt-link">
                    <a href="">{Strings.recoverPwd}</a>
                  </div>
                  <button className="btn-theme btn login-btn" type="submit">
                    Log In
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { LoginScreen };
