/**
 * Home slice
 * @format
 */
import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadedDataList: [],
  searchData: {},
  pagination: {
    page: 1,
  },
  query: {},
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setUploadData(state, action) {
      state.uploadedDataList = action.payload;
    },
    setSearchData(state, action) {
      state.searchData = action.payload;
    },
    setPaginationData(state, action) {
      state.pagination = { ...state.query, ...action.payload };
    },
    setSearchQuery(state, action) {
      state.query = action.payload;
    },
  },
});

export const homeReducer = homeSlice.reducer;

export const { setUploadData, setSearchData, setPaginationData, setSearchQuery } = homeSlice.actions;

// Actions
export const getuploadData = createAction("HOME/GET_UPLOAD_DATA");
export const addUserRecord = createAction("HOME/ADD_USER_RECORD");
export const searchRecord = createAction("HOME/SEARCH_RECORD");
export const getPaginatedData = createAction("HOME/GET_PAGINATEd_DATA");
