/**
 * Utils
 * @format
 */

import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

// Export functions
export function getExportFileBlob({ columns, data, fileType, fileName }) {
  // export file type xlsx
  if (fileType === "xlsx") {
    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }

  // export file type PDF
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const doc = new JsPDF(orientation, unit, size);
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);
    return false;
  }

  // Other formats goes here
  return false;
}
