/**
 * Root Reducer
 * combine all reducers to create root reducer
 * @format
 */
import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import { commonReducer } from "@src/pages/common";
import { homeReducer } from "@src/pages/home/slice";
import { searchReducer } from "@src/pages/search/slice";
import { eBayReducer } from "@src/pages/eBay";
import { sellerReducer } from "@src/pages/seller";

/*-----[ persist configurations ]------*/
const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [],
  blacklist: ["commonReducer"],
};
const commonPersistConfig = {
  key: "commonReducer",
  storage,
  blacklist: ["loaders"],
};

const appReducer = combineReducers({
  commonReducer: persistReducer(commonPersistConfig, commonReducer),
  homeReducer,
  searchReducer,
  eBayReducer,
  sellerReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  return appReducer(state, action);
};

const persistRootReducer = persistReducer(rootPersistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export { persistRootReducer };
