/**
 * eBay Redirect Page
 * @format
 */
import React from "react";

import { Images } from "@src/constants";
import { useLocation } from "react-router-dom";

function RedirectScreen({ props }) {
  const { search } = useLocation();

  return (
    <section className="login">
      {/* <!-- patterns --> */}
      <div className="absolute-item one">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      <div className="absolute-item two">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      <div className="absolute-item three">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      <div className="absolute-item four">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      {/* <!-- illustration --> */}
      {/* <!-- navigation --> */}

      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          {/* <!-- logo --> */}
          <a className="navbar-brand" href="#">
            {" "}
            <img src={Images.logo} alt="" />{" "}
          </a>
        </div>
      </nav>
      <div className="container-fluid p-16vh redirect-sec">
        <div className="row justify-content-end">
          <div className="col-lg-3">
            <img
              src={Images.redirect}
              alt=""
              className="img-fluid redirect-img"
            />
          </div>
          <div className="col-lg-6 text-center text-md-start">
            <div className="login-title xl">
              Please click on the link below to redirect to eBay to Authorize
              access
            </div>
            <a
              className="btn-theme btn"
              href={search?.replace("?redirect=", "") ?? ""}
            >
              Redirect to eBay
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export { RedirectScreen };
