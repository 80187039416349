/**
 * Seller Sagas
 * @format
 */

import { call, put, select, takeLatest } from "redux-saga/effects";
import FileDownload from "js-file-download";
import axios from "axios";

import { endPoints, httpRequest } from "@src/services";
import { createLoader, selectAuthToken } from "@src/pages/common";
import { BASE_URL } from "@src/config";
import {
  getSellerListing,
  setSellerSearch,
  requestReport,
  downloadReport,
  uploadReport,
  downloadUploadedReport,
  setSellerListingPage,
  updateSellerSearch,
  setListingLastIndex,
  getPaginatedData,
} from "./slice";

/**
 * Get Seller listing
 * @param {*} name
 * @param {*} status
 * @param {*} page
 * @param {*} limit
 */
function* getSellerListingSaga({ payload }) {
  const loader = createLoader();
  try {
    yield put(loader.present());
    const sellerData = yield httpRequest.post(endPoints.sellerSearch, payload);
    // if (sellerData?.length > 0) {
    //   yield put(setSellerListingPage());
    // } else {
    //   yield put(setListingLastIndex());
    // }
    if (sellerData?.result?.length > 0) {
      yield put(setSellerListingPage());
    } else {
      yield put(setSellerSearch({ ...sellerData, payload }));
    }
  } catch (error) {
  } finally {
    yield put(loader.dismiss());
  }
}

/**
 * Request Seller report for download
 * @param {*} payload
 */
function* requestReportSaga({ payload }) {
  const loader = createLoader();
  const { id, formData } = payload;
  try {
    yield put(loader.present());
    yield httpRequest.get(endPoints.sellerListing + `?id=${id}`);
    yield call(getSellerListingSaga, { payload: formData });
  } catch (error) {
  } finally {
    yield put(loader.dismiss());
  }
}

/**
 * Download report for selected record
 * @param {*} id
 */
function* downloadReportSaga({ payload: id }) {
  const loader = createLoader();
  try {
    yield put(loader.present());
    const token = yield select(selectAuthToken);
    const response = yield axios.get(BASE_URL + endPoints.sellerListingReport + `?id=${id}`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const fileName = response.headers["x-download-filename"];
    const otherFiles = fileName.indexOf(",") > 0 ? fileName.split(",") : [];
    yield FileDownload(response.data, otherFiles.length == 0 ? fileName : otherFiles[0]);

    if (otherFiles.length > 1) {
      for (let i = 1; i < otherFiles.length; i++) {
        const response = yield axios.get(BASE_URL + endPoints.sellerListingReport + `?id=${id}&fileName=${otherFiles[i]}`, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fileName = response.headers["x-download-filename"];
        yield FileDownload(response.data, fileName);
      }
    }
  } catch (error) {
  } finally {
    yield put(loader.dismiss());
  }
}

/**
 * Upload Document for a selected record
 * @param {file} payload
 */
function* uploadReportSaga({ payload }) {
  const loader = createLoader();
  const { file, dataId, formData: listingData } = payload;
  try {
    yield put(loader.present());
    let formData = new FormData();
    formData.append("file", file);
    yield axios.post(BASE_URL + endPoints.sellerUploadRevisions, formData, {
      params: { id: dataId },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    yield call(getSellerListingSaga, { payload: listingData });
  } catch (error) {
  } finally {
    yield put(loader.dismiss());
  }
}

/**
 * Download uploaded report
 * @param {id} payload
 * @param {uploadID} payload
 */
function* downloadUploadedReportSaga({ payload }) {
  const loader = createLoader();
  const { id, uploadID } = payload;
  try {
    yield put(loader.present());
    const token = yield select(selectAuthToken);

    const response = yield axios.get(BASE_URL + endPoints.sellerGetRevisions + `?id=${id}&uploadID=${uploadID}`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const fileName = response.headers["x-download-filename"];
    yield FileDownload(response.data, fileName);
  } catch (error) {
  } finally {
    yield put(loader.dismiss());
  }
}

function* getPaginatedDataSaga({ payload }) {
  const loader = createLoader();
  try {
    yield put(loader.present());
    const sellerData = yield httpRequest.post(endPoints.sellerSearch, payload);
    yield put(setSellerSearch({ ...sellerData, payload }));
  } catch (error) {
  } finally {
    yield put(loader.dismiss());
  }
}

export function* sellerSagas() {
  yield takeLatest(getSellerListing, getSellerListingSaga);
  yield takeLatest(requestReport, requestReportSaga);
  yield takeLatest(downloadReport, downloadReportSaga);
  yield takeLatest(uploadReport, uploadReportSaga);
  yield takeLatest(downloadUploadedReport, downloadUploadedReportSaga);
  yield takeLatest(getPaginatedData, getPaginatedDataSaga);
}
