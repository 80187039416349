/**
 * Seller selectors
 * @format
 */

import type { RootState } from "@src/redux";

const sellerReducer = (state: RootState) => state.sellerReducer;

export const selectSellerSearchData = (state: RootState) =>
  sellerReducer(state).sellerSearchData;

export const selectSellerListingPage = (state: RootState) =>
  sellerReducer(state).pageNumber;

export const selectSellerListingLastIndex = (state: RootState) =>
  sellerReducer(state).lastIndex;
