/**
 * Home Screen
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Header, DataTable } from "@src/components";
import { MenuBar } from "@src/pages/common";
import { selectUploadedDataList } from "../selectors";
import { addUserRecord, getuploadData } from "../slice";

function HomeScreen() {
  const uploadedDataList = useSelector(selectUploadedDataList);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: null,
    company: null,
    email: null,
    file: null,
  });
  const tableRef = useRef();

  useEffect(() => {
    dispatch(getuploadData());
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );

  return (
    <div>
      <div className="main">
        <Header />
        <MenuBar />
        <div className="main-content">
          {/* Data Table */}
          <DataTable
            columns={columns}
            data={uploadedDataList}
            tableRef={tableRef}
            title={"Recently Uploaded Spreadsheet"}
          />
        </div>
      </div>

      {/* Add New Record Modal */}
      <div
        className="modal fade"
        id="modaladd"
        aria-labelledby="modaladdLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={() => dispatch(addUserRecord(formData))}>
              <div className="modal-body">
                <div className="heading theme">Upload Spreadsheet</div>
                <div className="sub-heading">
                  Enter basic details here to show in data fields
                </div>

                <input
                  className="inpt form-control"
                  type="text"
                  placeholder="Enter Name"
                  onChange={(name) => setFormData({ ...formData, name })}
                  required
                />
                <input
                  className="inpt form-control"
                  type="text"
                  placeholder="Company Name"
                  onChange={(company) => setFormData({ ...formData, company })}
                  required
                />
                <input
                  className="inpt form-control"
                  type="email"
                  placeholder="Enter Email ID"
                  onChange={(email) => setFormData({ ...formData, email })}
                  required
                />
                <input
                  className="inpt form-control"
                  type="file"
                  name=""
                  id=""
                  required
                  onChange={(file) =>
                    setFormData({ ...formData, file: file.target.files[0] })
                  }
                />
              </div>
              <div className="modal-footer d-flex">
                <button
                  type="button"
                  className="btn btn-theme outline me-2"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <input
                  type={"submit"}
                  className="btn btn-theme"
                  value={"Add"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { HomeScreen };
