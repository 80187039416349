/**
 * eBay Error Screen
 * @format
 */
import React from "react";

import { Images } from "@src/constants";

function ErrorScreen() {
  return (
    <section className="login">
      {/* <!-- patterns --> */}
      <div className="absolute-item one">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      <div className="absolute-item two">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      <div className="absolute-item three">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      <div className="absolute-item four">
        <div className="circle"></div>
        <div className="circle two"></div>
      </div>
      {/* <!-- illustration --> */}
      {/* <!-- navigation --> */}

      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          {/* <!-- logo --> */}
          <a className="navbar-brand" href="#">
            {" "}
            <img src={Images.logo} alt="" />{" "}
          </a>
        </div>
      </nav>
      <div className="container-fluid p-16vh redirect-sec">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-6 text-center text-md-start order-2 order-lg-1">
            <div className="login-title xl">
              Authorization <span style={{ color: "#FC476A" }}>Failed</span>
            </div>
            <div className="heading">
              Your authorization to eBay got failed. Please try again
            </div>
            <button className="btn-theme btn">Return to Menu</button>
          </div>
          <div className="col-lg-5 order-lg-2">
            <img src={Images.error} alt="" className="img-fluid redirect-img" />
          </div>
        </div>
      </div>
    </section>
  );
}

export { ErrorScreen };
