/**
 * Search Screen
 * @format
 */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Images } from "@src/constants";
import { MenuBar } from "@src/pages/common";
import { selectSearchData } from "@src/pages/home/selectors";
import { DataTable, Header } from "@src/components";
import { searchRecord, getPaginatedData } from "@src/pages/home/slice";

function SearchScreen() {
  const searchData = useSelector(selectSearchData);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [query, setQuery] = useState({
    ean: searchData?.payload?.ean || "",
    brand: searchData?.payload?.brand || "",
    partNo: searchData?.payload?.partNo || "",
    pageNo: 1,
    pageSize: 80,
  });
  const tableRef = useRef();

  // useEffect(() => {
  //   if (searchData) {
  //     const keys = searchData?.results?.length > 1 ? Object.keys(searchData?.results[0]) : [];
  //     let newUpdateKeys = removeKeysFromArray(
  //       ["category", "mandatorySpecs", "optimizedInfos", "usedRecommendedSpecs", "recommendedSpecs", "compatibility"],
  //       keys
  //     );
  //     console.log("🚀 ~ file: listings.js:27 ~ useEffect ~ newUpdateKeys:", newUpdateKeys);
  //     setColumns([
  //       ...newUpdateKeys
  //         .filter((value) => value !== "optimizedInfos")
  //         .map((header) => {
  //           return {
  //             Header: header,
  //             accessor: header,
  //             Cell: ({ value }) => {
  //               if (header === "listingURL") {
  //                 console.log("🚀 ~ file: searchScreen.js:44 ~ .map ~ header:", header);
  //                 return (
  //                   <a href={value} target="_blank">
  //                     {value}
  //                   </a>
  //                 );
  //               } else if (header === "imageURL") {
  //                 return <img src={value} className="img-fluid border" style={{ height: "75px", width: "75px" }} alt="" />;
  //               } else {
  //                 return !value ? "-" : value;
  //               }
  //             },
  //           };
  //         }),
  //     ]);
  //   }
  // }, [searchData]);

  const customColumns = React.useMemo(
    () => [
      {
        Header: "Icon",
        accessor: "imageURL", // accessor is the "key" in the data
        Cell: (props) => {
          return (
            <a href={props.row.original.listingURL} target="_blank" >
              <img src={props.row.original.imageURL} className="border" style={{ height: "75px", width: "75px" }} alt="" />
            </a>
          );
        },
      },
      {
        Header: "Item number ",
        accessor: "itemNumber",
        Cell: (props) => {
          return (
            <a href={props.row.original.listingURL} target="_blank">
              {props.row.original.itemNumber}
            </a>
          );
        },
      },
      {
        Header: "Seller",
        accessor: "seller",
      },
      {
        Header: "Brand",
        accessor: "brand",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: (props) => {
          return (
            <div>
              {props.row.original.currency} {props.row.original.price}
            </div>
          );
        },
      },
      {
        Header: "Listed",
        accessor: "listingDate",
      },
      {
        Header: "Sold",
        accessor: "quantitySold",
      },
      {
        Header: "UPC",
        accessor: "upc",
      },
      {
        Header: "EAN",
        accessor: "ean",
      },
      {
        Header: "MPN",
        accessor: "mpn",
      },
    ],
    []
  );
  const data = searchData?.results;

  const onSearch = () => {
    dispatch(searchRecord(query));
  };

  const handlePageChange = (selectedPage) => {
    const prevQuery = { ...searchData?.payload };
    delete prevQuery.pageNo;
    dispatch(
      getPaginatedData({
        ...prevQuery,
        pageNo: selectedPage,
      })
    );
  };

  return (
    <div>
      <div className="main">
        <Header />
        <MenuBar />
        <div className="main-content">
          <div className="filter-block">
            <div className="flex-item inputs">
              <input
                type="text"
                className="form-control inpt"
                placeholder="EAN Number"
                value={query.ean}
                onChange={(e) => setQuery({ ...query, ean: e.target?.value })}
                required
              />
              <input
                type="text"
                className="form-control inpt"
                placeholder="Brand"
                value={query.brand}
                onChange={(e) => setQuery({ ...query, brand: e.target?.value })}
                required
              />
              <input
                type="text"
                className="form-control inpt"
                placeholder="Part Number"
                value={query.partNo}
                onChange={(e) => setQuery({ ...query, partNo: e.target?.value })}
                required
              />
              <a className="btn btn-theme search-btn" onClick={onSearch}>
                {" "}
                <img src={Images.searchIcon} alt="" />{" "}
              </a>
            </div>
          </div>
          {/* Data Table */}
          {Object.keys(searchData).length !== 0 && (
            <DataTable
              columns={customColumns}
              data={data}
              ref={tableRef}
              title={"Search"}
              totalPages={searchData?.totalPages}
              currentPage={searchData?.payload?.pageNo}
              onPageChange={handlePageChange}
              totalData={searchData?.totalResults}
            />
          )}
        </div>
      </div>
      {/* <!-- modal-add --> */}
      {/* <!-- Button trigger modal --> */}

      {/* Modal */}
      <div className="modal fade" id="modaladd" aria-labelledby="modaladdLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="heading theme">Upload Spreadsheet</div>
              <div className="sub-heading">Enter basic details here to show in data fields</div>
              <form action="">
                <input className="inpt form-control" type="text" placeholder="Enter Name" />
                <input className="inpt form-control" type="text" placeholder="Company Name" />
                <input className="inpt form-control" type="email" placeholder="Enter Email ID" />
                <input className="inpt form-control" type="file" name="" id="" />
              </form>
            </div>
            <div className="modal-footer d-flex">
              <button type="button" className="btn btn-theme outline me-2" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-theme">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { SearchScreen };

