/**
 * Search selectors
 * @format
 */

import type { RootState } from "@app/redux";

const homeReducer = (state: RootState) => state.homeReducer;

export const selectUploadedDataList = (state: RootState) => homeReducer(state).uploadedDataList;

export const selectSearchData = (state: RootState) => homeReducer(state).searchData;

export const selectSearchPaginationData = (state: RootState) => homeReducer(state).pagination;
