/**
 * Home Sagas
 * @format
 */
import { takeLatest, put, call } from "redux-saga/effects";

import { endPoints, httpRequest } from "@src/services";
import { createLoader, setAuthToken } from "@src/pages/common";
import { getuploadData, addUserRecord, setUploadData, searchRecord, setSearchData, getPaginatedData } from "./slice";

/**
 * File Upload
 * @param {file}
 */
function* uploadFileSaga(file) {
  try {
    const data = new FormData();
    data.append("file", file);
    yield httpRequest.post(endPoints.uploadFile, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(error);
  }
}

/**
 * Add New User Details
 * @param {name}
 * @param {email}
 * @param {company}
 * @param {file}
 */
function* addUserRecordSaga({ payload }) {
  const loader = createLoader();
  try {
    yield put(loader.present());
    const { file, name, company, email } = payload;
    yield httpRequest.post(endPoints.uploadData, {
      name,
      email,
      company,
    });
    if (file) {
      yield call(uploadFileSaga, file);
    }
    yield call(getUploadDataSaga);
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loader.dismiss());
  }
}

/**
 * Get User Uploads
 */
function* getUploadDataSaga() {
  try {
    const response = yield httpRequest.get(endPoints.userUploads);
    console.log({ response });
    yield put(setUploadData(response));
  } catch (error) {
    console.log({ error });
  }
}

/**
 *
 * @param {brand} payload
 */
function* searchDataSaga({ payload }) {
  const loader = createLoader();
  try {
    yield put(loader.present());
    const data = yield httpRequest.get(endPoints.queryData, {
      params: payload,
    });
    yield put(setSearchData({ ...data, payload }));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loader.dismiss());
  }
}

function* getPaginatedDataSaga({ payload }) {
  const loader = createLoader();
  try {
    yield put(loader.present());
    const data = yield httpRequest.get(endPoints.queryData, {
      params: payload,
    });
    yield put(setSearchData({ ...data, payload }));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loader.dismiss());
  }
}

export function* homeSaga() {
  yield takeLatest(addUserRecord, addUserRecordSaga);
  yield takeLatest(getuploadData, getUploadDataSaga);
  yield takeLatest(searchRecord, searchDataSaga);
  yield takeLatest(getPaginatedData, getPaginatedDataSaga);
}
