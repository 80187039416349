import $ from "jquery";
// function openNav() {
//     document.getElementById("mySidenav").style.width = "250px";
//     document.getElementById("main").style.marginLeft = "250px";
//   }

//   /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
//   function closeNav() {
//     document.getElementById("mySidenav").style.width = "70px";
//     document.getElementById("main").style.marginLeft = "70px";
//     document.getElementById("main").style.marginLeft = "70px";
//   };

$(document).on("click", ".arrow-sidebar", function () {
  $(this).toggleClass("active");
  $(".sidenav").toggleClass("expand");
  $(".main").toggleClass("ml-70");
});

$(document).on("click", ".filter", function () {
  $(".filter-block").toggleClass("expand");
  $(".main-table").toggleClass("expand");
});
