/**
 * Routes Constants
 */

// Auth Routes
export const AuthRoutes = {
  LoginScreen: "/",
};

//Main Routes
export const MainRoutes = {
  HomeScreen: "/home",
  SearchScreen: "/search",
  EbayAuth: "/eBayAuthorize",
  EbayListing: "/eBayListing",
  RedirectScreen: "/redirectScreen",
  SuccessScreen: "/successScreen",
  ErrorScreen: "/errorScreen",
  SellerListingScreen:'/sellerListing'
};
