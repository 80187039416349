/**
 * eBay Authorize Screen
 * @format
 */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Header } from "@src/components";
import { MenuBar, showToast } from "@src/pages/common";
import { Images } from "@src/constants";
import { getAuthLink } from "../slice";
import { selectAuthLink } from "../selectors";

function Authorize() {
  const dispatch = useDispatch();
  const link = useSelector(selectAuthLink);
  const [name, setName] = useState("");

  const handleAuthLink = () => {
    dispatch(getAuthLink({ sellerName: name }));
  };

  const onCopy = () => {
    navigator.clipboard.writeText(link);
    dispatch(showToast("Link Copied"));
    setTimeout(() => dispatch(showToast(null)), 1000);
  };

  return (
    <>
      <div className="main">
        <Header />
        <MenuBar />
        <div className="main-content">
          <div className="flex-item no-wrap">
            <input
              type="text"
              className="form-control inpt search"
              placeholder="eBay Seller Name"
              value={name}
              onChange={(e) => setName(e.target?.value)}
              required
            />
            <a className="btn btn-theme no-wrap" onClick={handleAuthLink}>
              {" "}
              Authorize Link{" "}
            </a>
          </div>
          {link && (
            <div className="links">
              <div className="flex-item">
                <a className="btn btn-theme dark " onClick={onCopy}>
                  {" "}
                  <img src={Images.copy} alt="" /> <span>Copy</span>
                </a>
                <div className="link-box">
                  <img src={Images.link} alt="" className="me-2" />
                  <a href={link}>{link}</a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { Authorize };
