/**
 * SideBar
 * @format
 */

import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Images } from "@src/constants";
import { AuthRoutes, MainRoutes } from "@src/constants/routes";
import { logoutApp } from "../slice";

function MenuBar() {
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return path !== AuthRoutes.LoginScreen ? (
    <>
      {/* <!-- sidebar --> */}
      <div className="sidenav">
        <div className="sidenav-items">
          <a
            href={"#"}
            onClick={() => navigate(MainRoutes.HomeScreen)}
            className={path === MainRoutes.HomeScreen ? "active" : ""}
          >
            <span
              className="icon"
              data-toggle="tooltip"
              data-placement="right"
              title="Home"
            >
              <img src={Images.home} alt="" />
            </span>
            <span className="name">Home</span>
          </a>
          <a
            href={"#"}
            onClick={() => navigate(MainRoutes.SearchScreen)}
            className={path === MainRoutes.SearchScreen ? "active" : ""}
          >
            <span
              className="icon"
              data-toggle="tooltip"
              data-placement="right"
              title="Search Data"
            >
              <img src={Images.search} alt="" />
            </span>
            <span className="name">Search Data</span>
          </a>
          <a
            href={"#"}
            onClick={() => navigate(MainRoutes.EbayAuth)}
            className={path === MainRoutes.EbayAuth ? "active" : ""}
          >
            <span
              className="icon"
              data-toggle="tooltip"
              data-placement="right"
              title="Authorize eBay"
            >
              <img src={Images.linkReen} alt="" />
            </span>
            <span className="name">Authorize eBay</span>
          </a>
          <a
            href={"#"}
            onClick={() => navigate(MainRoutes.EbayListing)}
            className={path === MainRoutes.EbayListing ? "active" : ""}
          >
            <span
              className="icon"
              data-toggle="tooltip"
              data-placement="right"
              title="eBay Listings"
            >
              <img src={Images.text} alt="" />
            </span>
            <span className="name">eBay Listings</span>
          </a>
          <a
            href={"#"}
            onClick={() => navigate(MainRoutes.SellerListingScreen)}
            className={path === MainRoutes.SellerListingScreen ? "active" : ""}
          >
            <span
              className="icon"
              data-toggle="tooltip"
              data-placement="right"
              title="Seller"
            >
              <img src={Images.text} alt="" />
            </span>
            <span className="name">Seller</span>
          </a>
          <a href="#">
            <span
              className="icon"
              data-toggle="tooltip"
              data-placement="right"
              title="User Access"
            >
              <img src={Images.user} alt="" />
            </span>
            <span className="name">User Access</span>
          </a>
          <a href="#">
            <span
              className="icon"
              data-toggle="tooltip"
              data-placement="right"
              title="Billing"
            >
              <img src={Images.text} alt="" />
            </span>
            <span className="name">Billing</span>
          </a>
          <a type="button" onClick={() => dispatch(logoutApp())}>
            <span
              className="icon"
              data-toggle="tooltip"
              data-placement="right"
              title="Log Out"
            >
              <img src={Images.logout} alt="" />
            </span>
            <span className="name">Log Out</span>
          </a>
        </div>
      </div>
      {/* <!-- sidebar-arrow --> */}
      <div className="arrow-sidebar">
        <img src={Images.arrowLeft} alt="" />
      </div>
    </>
  ) : null;
}

export { MenuBar };
