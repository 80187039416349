/**
 * eBay selectors
 * @format
 */

import type { RootState } from '@app/redux';

const eBayReducer = (state: RootState) => state.eBayReducer;

export const selectAuthLink = (state: RootState) => eBayReducer(state).link;
export const selectEBayList = (state: RootState) => eBayReducer(state).listing;