/**
 * Seller slice
 * @format
 */

const { createSlice, createAction } = require("@reduxjs/toolkit");

const initialState = {
  sellerSearchData: {},
  pageNumber: 1,
  lastIndex: false,
  pagination: {
    page: 1,
    perPage: 25,
  },
};

const sellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {
    setSellerSearch(state, action) {
      state.sellerSearchData = action.payload;
    },
    updateSellerSearch(state, action) {
      state.sellerSearchData.push(...action.payload);
    },

    setSellerListingPage(state, action) {
      state.pageNumber = state.pageNumber + 1;
    },
    setListingLastIndex(state, action) {
      state.pagination = true;
    },
    setPagination(state, action) {
      state.pagination = action.payload;
    },
  },
});

// Reducer
export const sellerReducer = sellerSlice.reducer;

// Actions
export const { setSellerSearch, updateSellerSearch, setSellerListingPage, setListingLastIndex, setPagination } = sellerSlice.actions;

export const getSellerListing = createAction("SELLER/GET_SELLER_LISTING");
export const requestReport = createAction("SELLER/REQUEST_REPORT");
export const downloadReport = createAction("SELLER/DOWNLOAD_REPORT");
export const uploadReport = createAction("SELLER/UPLOAD_REPORT");
export const getPaginatedData = createAction("SELLER/GET_PAGINATED_DATA");
export const downloadUploadedReport = createAction("SELLER/DOWNLOAD_UPLOAD_REPORT");
