/**
 * eBay listing Screen
 * @format
 */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Images } from "@src/constants";
import { DataTable, Header } from "@src/components";
import { MenuBar } from "@src/pages/common";
import { selectEBayList } from "../selectors";
import { getEBayList, uploadEBayFile } from "../slice";

function Listings() {
  const list = useSelector(selectEBayList);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [seller, setSeller] = useState(list?.payload?.seller || "");
  const [file, setFile] = useState();
  const tableRef = useRef();

  // useEffect(() => {
  //   const keys = list.results?.length > 1 ? Object.keys(list?.results[0]) : [];
  //   let newUpdateKeys = removeKeysFromArray(
  //     ["category", "mandatorySpecs", "optimizedInfos", "usedRecommendedSpecs", "recommendedSpecs", "compatibility"],
  //     keys
  //   );

  //   setColumns([
  //     ...newUpdateKeys
  //       .filter((value) => value !== "endtime")
  //       .map((header) => {
  //         return {
  //           Header: header,
  //           accessor: header, // accessor is the "key" in the data
  //           Cell: ({ value }) => {
  //             if (header === "listingURL") {
  //               console.log("🚀 ~ file: searchScreen.js:44 ~ .map ~ header:", header);
  //               return (
  //                 <a href={value} target="_blank">
  //                   {value}
  //                 </a>
  //               );
  //             } else if (header === "imageURL") {
  //               return <img src={value} className="img-fluid border" style={{ height: "75px", width: "75px" }} alt="" />;
  //             } else {
  //               return !value ? "-" : value;
  //             }
  //           },
  //         };
  //       }),
  //   ]);
  // }, [list]);

  const customColumns = React.useMemo(
    () => [
      {
        Header: "Icon",
        accessor: "imageURL", // accessor is the "key" in the data
        Cell: (props) => {
          return (
            <a href={props.row.original.listingURL} target="_blank">
              <img src={props.row.original.imageURL} className="border" style={{ height: "75px", width: "75px" }} alt="" />
            </a>
          );
        },
      },
      {
        Header: "Item number ",
        accessor: "itemNumber",
        Cell: (props) => {
          return (
            <a href={props.row.original.listingURL} target="_blank">
              {props.row.original.itemNumber}
            </a>
          );
        },
      },
      {
        Header: "Seller",
        accessor: "seller",
      },
      {
        Header: "Brand",
        accessor: "brand",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: (props) => {
          return (
            <div>
              {props.row.original.currency} {props.row.original.price}
            </div>
          );
        },
      },
      {
        Header: "Listed",
        accessor: "listingDate",
      },
      {
        Header: "Sold",
        accessor: "quantitySold",
      },
      {
        Header: "UPC",
        accessor: "upc",
      },
      {
        Header: "EAN",
        accessor: "ean",
      },
      {
        Header: "MPN",
        accessor: "mpn",
      },
    ],
    []
  );
  const handleSearch = () => {
    dispatch(getEBayList({ seller, pageNo: 1, pageSize: 50 }));
  };

  const handleUploadFile = () => {
    dispatch(uploadEBayFile({ seller, file }));
  };

  const handlePageChange = (selectedPage) => {
    const prevQuery = { ...list?.payload };
    delete prevQuery.pageNo;
    dispatch(
      getEBayList({
        ...prevQuery,
        pageNo: selectedPage,
      })
    );
  };

  return (
    <div>
      <div className="main">
        <Header />
        <MenuBar />
        <div className="main-content">
          <div className="filter-block">
            <div className="flex-item inputs">
              <input
                type="text"
                className="form-control inpt"
                placeholder="Seller Name"
                value={seller}
                onChange={(e) => setSeller(e.target.value)}
                required
              />

              <a className="btn btn-theme search-btn" onClick={handleSearch}>
                {" "}
                <img src={Images.searchIcon} alt="" />{" "}
              </a>
            </div>
          </div>
          {/* Data Table */}
          <DataTable
            columns={customColumns}
            data={list?.results || []}
            ref={tableRef}
            title={"Listings"}
            // exportType={"xlsx"}
            uploadButton={"Upload"}
            totalPages={list?.totalPages}
            currentPage={list?.payload?.pageNo}
            onPageChange={handlePageChange}
            totalData={list?.totalResults}
          />
        </div>
      </div>
      {/* <!-- modal-add --> */}
      {/* <!-- Button trigger modal --> */}

      {/* Modal */}
      <div className="modal fade" id="modaladd" aria-labelledby="modaladdLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="heading theme">Upload Spreadsheet</div>
              <div className="sub-heading">Enter basic details here to show in data fields</div>
              <form action="">
                <input
                  className="inpt form-control"
                  type="file"
                  name=""
                  id=""
                  // value={file}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </form>
            </div>
            <div className="modal-footer d-flex">
              <button type="button" className="btn btn-theme outline me-2" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" className="btn btn-theme" data-bs-dismiss="modal" onClick={handleUploadFile}>
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Listings };

function removeKeysFromArray(keysToRemove, inputArray) {
  // Create a new array with items that do not have keysToRemove
  const filteredArray = inputArray.filter((item) => !keysToRemove.includes(item));

  return filteredArray;
}
