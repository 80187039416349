/**
 * Loader Message Component
 * @format
 */
import React from "react";
import { useSelector } from "react-redux";

import { selectLoader } from "@src/pages/common";
import { Images } from "@src/constants";
import { Backdrop } from "@mui/material";

function Loader() {
  const loader = useSelector(selectLoader);
  return loader ? (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
      <div className="loader">
        <div className="position-absolute top-50 start-50" role="status">
          <img src={Images.loader} alt="" />
        </div>
      </div>
    </Backdrop>
  ) : null;
}

export { Loader };
