/**
 * Root Saga
 * @format
 */

import { spawn } from "redux-saga/effects";

import { homeSaga } from "@src/pages/home/sagas";
import { commonSagas } from "@src/pages/common/sagas";
import { searchSaga } from "@src/pages/search/sagas";
import { authSaga } from "@src/pages/auth/sagas";
import { eBaySaga } from "@src/pages/eBay";
import { sellerSagas } from "@src/pages/seller";

// Adding Sagas to RootSaga
function* rootSaga() {
  yield spawn(homeSaga);
  yield spawn(commonSagas);
  yield spawn(authSaga);
  yield spawn(eBaySaga);
  // yield spawn(searchSaga);
  yield spawn(sellerSagas);
}

export { rootSaga };
