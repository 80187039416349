/**
 * Routing Module
 * @format
 */

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthChecker } from "./authRouters";
import { AuthRoutes, MainRoutes } from "@src/constants/routes";
import { Loader, PopUp } from "@src/components";

// Screens
import { LoginScreen } from "@src/pages/auth";
import { HomeScreen } from "@src/pages/home";
import { SearchScreen } from "@src/pages/search";
import {
  Authorize,
  Listings,
  RedirectScreen,
  SuccessScreen,
  ErrorScreen,
} from "@src/pages/eBay";
import { SellerListingScreen } from "@src/pages/seller";

function AppRouter() {
  return (
    <div className="App">
      <BrowserRouter>
        <Loader />
        <PopUp />
        <Routes>
          <Route element={<AuthChecker />}>
            <Route path={MainRoutes.HomeScreen} element={<HomeScreen />} />
            <Route path={MainRoutes.SearchScreen} element={<SearchScreen />} />
            <Route path={MainRoutes.EbayAuth} element={<Authorize />} />
            <Route path={MainRoutes.EbayListing} element={<Listings />} />
            <Route path={MainRoutes.SellerListingScreen} element={<SellerListingScreen />} />
          </Route>
          <Route path={AuthRoutes.LoginScreen} element={<LoginScreen />} />
        </Routes>
        <Routes>
          <Route
            path={MainRoutes.RedirectScreen}
            element={<RedirectScreen />}
          />
          <Route path={MainRoutes.SuccessScreen} element={<SuccessScreen />} />
          <Route path={MainRoutes.ErrorScreen} element={<ErrorScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export { AppRouter };
