/**
 * Search Slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchData: {},
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchData(state, action) {
      state.searchData = action.payload;
    },
  },
});

//Search Reducer
export const searchReducer = searchSlice.reducer;

//Actions
export const { setSearchData } = searchSlice.actions;

export const getSearchData = createAction("SEARCH/GET_SEARCH");
export const getPaginatedData = createAction("SEARCH/GET_PAGINATED_DATA");

