/**
 * Api Endpoints
 * @format
 */
const endPoints = {
  login: "auth/signin",
  uploadData: "upload/data",
  uploadFile: "upload/file",
  userUploads: "uploads",
  queryData: "query",
  eBayAuth: "ebayauth",
  eBayListing: "ebay/listings",
  eBayFile: "ebay/revision/file",

  // Seller Search Endpoints
  sellerSearch: "seller/search2",
  sellerListing: "seller/listing/download",
  sellerListingReport: "seller/listing/download/report",
  sellerUploadRevisions: "seller/revision",
  sellerGetRevisions: "seller/revisions",
};
export default endPoints;
