/**
 * Auth Routers
 * @format
 */

import React from "react";
import { Navigate, Outlet, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { LoginScreen } from "@src/pages/auth";
import { selectAuthToken } from "@src/pages/common";
import { AuthRoutes } from "@src/constants/routes";

export const AuthRouter = () => {
  return (
    <>
      <Route path="login" element={<LoginScreen />} />
    </>
  );
};

export const AuthChecker = () => {
  const token = useSelector(selectAuthToken);
  const location = useLocation();

  return token ? (
    <Outlet />
  ) : (
    <Navigate to={AuthRoutes.LoginScreen} state={{ from: location }} replace />
  );
};
