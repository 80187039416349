/**
 * string constant
 * @format
 */

export const Strings = {
  appTitle: "EMotive",
  weclome: "Welcome Back",
  adminTitle: "Emotive Admin",
  login: "Log In",
  help: "Help",
  recoverPwd: "Recover password?",
};
