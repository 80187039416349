/**
 * Seller Listing Screen
 * @format
 */

import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Images } from "@src/constants";
import { DataTable, Header } from "@src/components";
import { MenuBar } from "@src/pages/common";
import { getSellerListing, requestReport, downloadReport, uploadReport, downloadUploadedReport, getPaginatedData } from "../slice";
import { selectSellerSearchData } from "../selectors";

const SellerListingScreen = () => {
  const sellerData = useSelector(selectSellerSearchData);
  const tableRef = useRef();
  const dispatch = useDispatch();

  const [file, setFile] = useState();
  const [dataId, setDataId] = useState();
  const [formData, setFormData] = useState({
    sellerName: sellerData?.sellerName || "",
    sellerStatus: sellerData?.sellerStatus || "All",
    pageNo: 1,
    pageSize: 80,
  });

  // render action button for a row
  const renderActionButtons = ({ row }) => {
    const { id, downloadReportAvailable, revisionsAllowed } = row?.original || {};
    return (
      <>
        <span
          title="Download Listings"
          onClick={() => {
            const answer = window.confirm("Do you want to download the seller’s listings");
            answer && dispatch(requestReport({ id, formData }));
          }}
        >
          <i className="bi bi-eye"></i>
        </span>
        {downloadReportAvailable && (
          <span
            title="Download Listing Report"
            className="p-4"
            onClick={() => {
              dispatch(downloadReport(id));
            }}
          >
            <i className="bi bi-file-earmark-arrow-down"></i>
          </span>
        )}
        {revisionsAllowed && (
          <span title="Upload Revision File" data-bs-toggle={"modal"} data-bs-target={"#modaladd"} onClick={() => setDataId(id)}>
            <i className="bi bi-upload"></i>
          </span>
        )}
      </>
    );
  };

  // Table Custom column
  const customColumns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id", // accessor is the "key" in the data
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => <div style={{ textAlign: "left" }}>{row.value}</div>,
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: renderActionButtons,
      },
      {
        Header: "Revision Responses",
        accessor: "uploadResponses.id",
        Cell: ({ row }) => {
          const { uploadResponses, id } = row?.original ?? {};
          if (!uploadResponses) {
            return <div />;
          }
          return (
            <select
              className="form-select"
              onChange={(e) => {
                if (e.target.value !== "Select") {
                  dispatch(
                    downloadUploadedReport({
                      id,
                      uploadID: Number(e.target.value),
                      formData,
                    })
                  );
                }
              }}
            >
              <option>Select</option>
              {row?.original?.uploadResponses?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          );
        },
      },
    ],
    []
  );

  const handlePageChange = (selectedPage) => {
    const prevQuery = { ...sellerData?.payload };
    delete prevQuery.pageNo;
    dispatch(
      getPaginatedData({
        ...prevQuery,
        pageNo: selectedPage,
      })
    );
  };

  return (
    <div className="main">
      <Header />
      <MenuBar />
      <div className="main-content">
        <div className="flex-item mb-4">
          <div className="heading">Seller Listing</div>
          <div className="flex-item buttons">
            <a className="btn btn-theme outline filter">
              <img src={Images.filter} alt="" />
            </a>
          </div>
          <div className="filter-block w-50">
            <div className="flex-item m-0 inputs justify-content-end">
              <input
                type="text"
                className="form-control inpt"
                placeholder="Seller Name"
                value={formData.sellerName}
                onChange={(e) => setFormData({ ...formData, sellerName: e.target?.value })}
                required
              />
              <select
                className="form-select mr-30"
                value={formData.sellerStatus}
                onChange={(e) => setFormData({ ...formData, sellerStatus: e.target?.value })}
              >
                <option value="All">All</option>
                <option value="Pending">Pending</option>
                <option value="Download Complete">Download Complete</option>
              </select>

              <a className="btn btn-theme search-btn" onClick={() => dispatch(getSellerListing(formData))}>
                <img src={Images.searchIcon} alt="" />
              </a>
            </div>
          </div>
        </div>

        <DataTable
          columns={customColumns}
          data={sellerData?.results || []}
          ref={tableRef}
          totalPages={sellerData?.totalPages}
          currentPage={sellerData?.payload?.pageNo}
          onPageChange={handlePageChange}
          totalData={sellerData?.totalResults}
        />
      </div>

      {/* Upload Seller Revisions Modal */}
      <div className="modal fade" id="modaladd" aria-labelledby="modaladdLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="heading theme">Upload Revisions</div>
              <div className="sub-heading">Choose file to upload</div>
              <form action="">
                <input className="inpt form-control" type="file" name="" id="" onChange={(e) => setFile(e.target.files[0])} />
              </form>
            </div>
            <div className="modal-footer d-flex">
              <button type="button" className="btn btn-theme outline me-2" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-theme"
                onClick={() => {
                  if (file) {
                    dispatch(uploadReport({ file, dataId, formData }));
                  }
                }}
                data-bs-dismiss={file && "modal"}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SellerListingScreen };
