import axios from "axios";

import * as config from "@src/config";
import endPoints from "./endpoints";
import { store } from "@src/redux/store";

const httpRequest = axios.create({
  baseURL: config.BASE_URL,
  timeout: config.API_TIMEOUT,
});

httpRequest.interceptors.request.use(
  (config) => {
    const token = store.getState().commonReducer.authToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (err) => Promise.reject(err)
);

httpRequest.interceptors.response.use(
  (res) => {
    if (res?.data?.error) {
      return Promise.reject(res?.data?.error);
    }
    return res.data;
  },
  (err) => Promise.reject(err)
);

export { httpRequest, endPoints };
