/**
 * eBay Saga
 */

import { put, takeLatest } from "redux-saga/effects";

import { endPoints, httpRequest } from "@src/services";
import { getAuthLink, getEBayList, setAuthLink, setListings, uploadEBayFile } from "./slice";
import { createLoader } from "../common";

/**
 *
 * @param {sellerName} payload
 */
function* authLinkSaga({ payload }) {
  const loader = createLoader();
  try {
    yield put(loader.present());
    const link = yield httpRequest.get(endPoints.eBayAuth, {
      params: payload,
    });
    yield put(setAuthLink(link));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loader.dismiss());
  }
}

/**
 *
 * @param {*} payload
 */
function* eBayListSaga({ payload }) {
  const loader = createLoader();
  const data = {
    ...payload,
  };
  try {
    yield put(loader.present());
    const list = yield httpRequest.post(endPoints.eBayListing, data, {
      params: { search: "" },
    });

    yield put(setListings({ ...list, payload }));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loader.dismiss());
  }
}

/**
 *
 * @param {file} payload
 */
function* uploadFileSaga({ payload }) {
  const loader = createLoader();
  const { seller, file } = payload;
  let formData = new FormData();
  formData.append("file", file);
  try {
    yield put(loader.present());
    const list = yield httpRequest.post(endPoints.eBayFile, formData, {
      params: { seller },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loader.dismiss());
  }
}

export function* eBaySaga() {
  yield takeLatest(getAuthLink, authLinkSaga);
  yield takeLatest(getEBayList, eBayListSaga);
  yield takeLatest(uploadEBayFile, uploadFileSaga);
}
