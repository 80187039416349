/**
 * Image source
 * All static images should be import here
 * @format
 */

import login from "@src/assets/images/login.svg";
import logo from "@src/assets/images/logo.svg";
import arrowLeft from "@src/assets/images/Arrow-left.svg";
import bitmap from "@src/assets/images/Bitmap.svg";
import download from "@src/assets/images/Download.svg";
import home from "@src/assets/images/Home.svg";
import logout from "@src/assets/images/Logout.svg";
import text from "@src/assets/images/text.svg";
import user from "@src/assets/images/User.svg";
import view from "@src/assets/images/View.svg";
import group73 from "@src/assets/images/Group73.svg";
import searchIcon from "@src/assets/images/search-icon.svg";
import filter from "@src/assets/images/Filter.svg";
import search from "@src/assets/images/search.svg";
import loader from "@src/assets/images/loader.gif";
import copy from "@src/assets/images/copy.svg";
import link from "@src/assets/images/LINK.svg";
import linkReen from "@src/assets/images/link-reen.svg";
import redirect from "@src/assets/images/redirect.svg";
import success from "@src/assets/images/success.svg";
import error from "@src/assets/images/error.svg";

export const Images = {
  login,
  logo,
  arrowLeft,
  bitmap,
  download,
  home,
  logout,
  text,
  user,
  view,
  searchIcon,
  search,
  group73,
  filter,
  loader,
  copy,
  link,
  linkReen,
  success,
  redirect,
  error,
};
