/**
 * Redux Store
 * create redux store with middleware,
 * enhancers & root reducer
 * configure redux persist
 * @format
 */

import { configureStore as reduxConfigureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";

import { persistRootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";

/*-----------[ configure store ]------------*/

const sagaMiddleware = createSagaMiddleware();

// New middleware can be added here
const middleware = [sagaMiddleware];

const store = reduxConfigureStore({
  reducer: persistRootReducer,
  middleware,
});

const persistor = persistStore(store);

// Run sagas
sagaMiddleware.run(rootSaga);

export { store, persistor };
