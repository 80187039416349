/**
 * Data Table Component
 * @format
 */
import React, { forwardRef, useImperativeHandle } from "react";
import { useTable, usePagination } from "react-table";
import { useExportData } from "react-table-plugins";
import { getExportFileBlob, Images } from "@src/constants";
import { Box, Pagination } from "@mui/material";

const DataTable = forwardRef(({ columns, data, title, exportType, uploadButton, currentPage, totalPages, onPageChange, totalData }, tableRef) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, exportData } = useTable(
    {
      columns,
      data,
      getExportFileBlob,
      autoResetPage: false,
    },
    usePagination,
    useExportData
  );

  useImperativeHandle(tableRef, () => ({
    exportFile(type, allExport) {
      exportData(type, allExport);
    },
  }));

  return (
    <>
      <div className="flex-item">
        <div className="heading">{title}</div>
        <div className="flex-item buttons">
          {exportType && (
            <button className="btn btn-theme outline" onClick={() => exportData(exportType || "pdf", false)}>
              Export
            </button>
          )}
          {uploadButton && (
            <button type="button" className="btn  btn-theme" data-bs-toggle="modal" data-bs-target="#modaladd">
              {uploadButton}
            </button>
          )}
          <a className="btn btn-theme outline filter">
            <img src={Images.filter} alt="" />
          </a>
        </div>
      </div>
      <div className="table-responsive main-table">
        {Object.keys(data).length !== 0 ? (
          <table id="customers" className="table table-home table-striped" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="text-capitalize" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="align-middle">
                          {cell.value === null ? "-" : cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="d-flex justify-content-center">
            <h5>No Data Found</h5>
          </div>
        )}
      </div>
      {totalData > 25 && <UseMyPagination totalPages={totalPages} page={currentPage} callBack={onPageChange} />}
    </>
  );
});

export { DataTable };

const UseMyPagination = ({ totalPages, callBack, page }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }} className="pagination-container">
      <Pagination page={page} count={totalPages} color="primary" onChange={(_, newPage) => callBack(newPage)} />
    </Box>
  );
};
