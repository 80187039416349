/**
 * header Component
 * @format
 */
import { useSelector } from "react-redux";

import { selectUser } from "@src/pages/common";
import { Images } from "@src/constants";

function Header() {
  const user = useSelector(selectUser);

  return (
    <div className="header">
      <div className="logo">
        <img src={Images.logo} alt="" />
      </div>
      <div className="user-detail">
        <img src={Images.bitmap} alt="" />
        <span>{user?.name}</span>
      </div>
    </div>
  );
}

export { Header };
