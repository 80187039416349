/**
 * PopUp Component
 * @format
 */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectToast, showToast } from "@src/pages/common";

function PopUp() {
  const message = useSelector(selectToast);
  const dispatch = useDispatch();
  useEffect(() => {
    message &&
      setTimeout(() => {
        dispatch(showToast(null));
      }, 1500);
  }, [message]);

  return message ? (
    <div className="position-fixed end-0 top-0 p-3 m-3 text-bg-secondary rounded w-25">
      <div className="d-flex align-items-center justify-content-between">
        <span className="pe-5">{message}</span>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => dispatch(showToast(null))}
        ></button>
      </div>
    </div>
  ) : null;
}

export { PopUp };
